<template>
	<div class="-empty">
		<div class="-wrap -tac">
			<div class="-icwarn"></div>
			<div class="-s15">操作被终止</div>
			<div class="-s12">无操作权限，请联系管理</div>
		</div>
	</div>
</template>

<script>
</script>

<style scoped="scoped">
.-empty { display: flex; justify-content: center; align-items: center; width: 100%; height: 100%; color: #545454; background: rgba(247,250,252,1);}
.-empty .-wrap { font-size: 16px; color: rgba(160,174,192,1); padding-bottom: 30px;}
.-empty .-wrap .-icwarn { font-size: 36px; color: #be3f36;}
.-empty .-wrap .-s15 { font-size: 15px; color: #5c6d77; padding-top: 10px; padding-bottom: 3px;}
.-empty .-wrap .-s12 { font-size: 12px;}
</style>
